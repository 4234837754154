import { toNullable } from 'fp-ts/lib/Option'
import { useQuery } from 'react-query'
import { getContacts, qk } from 'src/api'
import { DialogLoadingState } from '../dialog-loading'
import { DialogEnterEmailProps, DialogEnterEmail } from './dialog-enter-email'

type Props = DialogEnterEmailProps & {
  isOpen: boolean
}

export const DialogEnterEmailWithDefaultContact = ({
  isOpen,
  onClose,
  onSave,
  isLoading,
  title,
  submitButtonText,
}: Props) => {
  const $contacts = useQuery(qk.auth.user.contacts.toKey(), getContacts, {
    refetchOnWindowFocus: false,
  })

  if (isOpen) {
    if ($contacts.isSuccess) {
      return (
        <DialogEnterEmail
          title={title}
          isLoading={isLoading}
          onClose={onClose}
          onSave={onSave}
          submitButtonText={submitButtonText}
          defaultEmail={toNullable($contacts.data.contactEmail) ?? ''}
        />
      )
    }

    return <DialogLoadingState />
  }

  return null
}
