import { Stack, Typography } from '@mui/material'
import { isNone, isSome } from 'fp-ts/lib/Option'
import useTranslation from 'next-translate/useTranslation'
import { IEventArtistsByAct } from 'src/api'
import {
  ArtistActSection,
  eventArtistsToArtistSection,
  subHeadingContentSpacing,
} from 'src/modules/artist/card'

type Props = {
  artists: IEventArtistsByAct
}

export const ArtistsOneDay = ({ artists }: Props) => {
  const { t } = useTranslation()

  const mainAct = artists.MAIN
  const supportAct = artists.SUPPORT

  if (isNone(mainAct)) {
    return null
  }

  return (
    <Stack spacing={{ xs: 3, lg: 4 }}>
      <ArtistActSection
        artists={eventArtistsToArtistSection(mainAct.value)}
        cardSize="large"
        columns={2}
      />
      {isSome(supportAct) && (
        <Stack spacing={subHeadingContentSpacing}>
          <Typography variant="h3ExtraBold">{t('support_act')}</Typography>
          <ArtistActSection
            artists={eventArtistsToArtistSection(supportAct.value)}
            cardSize="small"
            columns={4}
          />
        </Stack>
      )}
    </Stack>
  )
}
