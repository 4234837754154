import { SvgIcon, SvgIconProps } from '@mui/material'

export const CopyIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 16 16">
    <path
      d="M13.2983 1.57031H6.99488C6.29961 1.57031 5.7342 2.13573 5.7342 2.83099V5.35236H3.21283C2.51756 5.35236 1.95215 5.91778 1.95215 6.61304V12.9165C1.95215 13.6117 2.51756 14.1771 3.21283 14.1771H9.51624C10.2115 14.1771 10.7769 13.6117 10.7769 12.9165V10.3951H13.2983C13.9936 10.3951 14.559 9.82967 14.559 9.13441V2.83099C14.559 2.13573 13.9936 1.57031 13.2983 1.57031ZM3.21283 12.9165V6.61304H9.51624L9.5175 12.9165H3.21283ZM13.2983 9.13441H10.7769V6.61304C10.7769 5.91778 10.2115 5.35236 9.51624 5.35236H6.99488V2.83099H13.2983V9.13441Z"
      fill="black"
      stroke="black"
      strokeWidth="0.35784"
    />
  </SvgIcon>
)
