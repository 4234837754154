import { CircularProgress, Dialog, Stack } from '@mui/material'

export const DialogLoadingState = () => (
  <Dialog
    open
    PaperProps={{
      sx: {
        '&&': {
          p: 3,
          width: 'auto',
        },
      },
    }}
  >
    <Stack direction="row" justifyContent="center" alignItems="center">
      <CircularProgress size={40} thickness={3.5} />
    </Stack>
  </Dialog>
)
