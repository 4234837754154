import { Button, Divider, Stack, Typography } from '@mui/material'
import useTranslation from 'next-translate/useTranslation'
import { ReactNode } from 'react'
import { LoadingButton } from '@mui/lab'

export const saveButtonSpacing = { xs: 2, lg: 3 }
export const SaveButton = ({
  isLoading,
  isDisabled,
}: {
  isLoading: boolean
  isDisabled?: boolean
}) => {
  const { t } = useTranslation()

  return (
    <LoadingButton
      variant="primary"
      size="medium"
      type="submit"
      loading={isLoading}
      sx={{ alignSelf: 'flex-start' }}
      disabled={isDisabled}
    >
      {t('save')}
    </LoadingButton>
  )
}

const Value = ({
  value,
  isDisabled,
  isEmptyValue,
  disabledColor,
}: {
  value: string | null
  isEmptyValue: boolean
  disabledColor: string
  isDisabled: boolean
}) => {
  const { t } = useTranslation()

  return (
    <Typography
      variant="bodyBold"
      sx={{ wordWrap: 'break-word' }}
      color={
        isDisabled
          ? disabledColor
          : isEmptyValue
          ? 'secondary8.main'
          : 'common.black'
      }
    >
      {value ?? t('not_provided')}
    </Typography>
  )
}

type Props = {
  label: string
  fieldName: string
  selectedField: string | null
  toggleMode: (fieldName: string | null) => void
  value: string | null | Array<string>
  editModeContent: ReactNode
  editModeButtonText: string
}

export const LabelValueEdit = ({
  label,
  fieldName,
  selectedField,
  toggleMode,
  value,
  editModeContent,
  editModeButtonText,
}: Props) => {
  const { t } = useTranslation()

  const isEditMode = fieldName === selectedField

  const preparedValue = value === '' ? null : value
  const isEmptyValue = preparedValue === null
  const actionLabelNonEditMode = isEmptyValue ? t('add') : editModeButtonText

  const isDisabled = selectedField !== null && selectedField !== fieldName
  const disabledColor = 'secondary7.main'

  const renderValue = Array.isArray(preparedValue) ? (
    <Stack spacing={{ xs: 0.5 }}>
      {preparedValue.map((value) => (
        <Value
          key={value}
          value={value}
          isEmptyValue={isEmptyValue}
          isDisabled={isDisabled}
          disabledColor={disabledColor}
        />
      ))}
    </Stack>
  ) : (
    <Value
      value={preparedValue}
      isEmptyValue={isEmptyValue}
      isDisabled={isDisabled}
      disabledColor={disabledColor}
    />
  )

  return (
    <>
      <Stack spacing={isEditMode ? { xs: 2, lg: 3 } : { xs: 0.5 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            variant="smallTextRegular"
            color={isDisabled ? disabledColor : 'secondary8.main'}
          >
            {label}
          </Typography>
          <Button
            variant="underline"
            onClick={() =>
              isEditMode ? toggleMode(null) : toggleMode(fieldName)
            }
            disabled={isDisabled}
          >
            <Typography
              variant="smallTextSemiBold"
              color={isDisabled ? disabledColor : 'secondary9.main'}
            >
              {isEditMode ? t('cancel') : actionLabelNonEditMode}
            </Typography>
          </Button>
        </Stack>
        {isEditMode ? editModeContent : renderValue}
      </Stack>
      <Divider flexItem />
    </>
  )
}
