import { Stack, Typography } from '@mui/material'
import { ResponsiveStyleValue } from '@mui/system'
import useTranslation from 'next-translate/useTranslation'
import { ReactNode } from 'react'

type LabelValueWrapperProps = {
  label: string
  children: ReactNode
  labelColor?: string
  spacing?: ResponsiveStyleValue<number>
}

export const LabelValueWrapper = ({
  label,
  children,
  labelColor,
  spacing,
}: LabelValueWrapperProps) => {
  return (
    <Stack spacing={spacing ?? { lg: 0.25 }}>
      <Typography
        component="div"
        variant="smallTextSemiBold"
        color={labelColor ?? 'secondary9.main'}
      >
        {label}
      </Typography>
      {children}
    </Stack>
  )
}

type Props = {
  label: string
  value: string | null | number
  textColorEmpty?: string
  labelColor?: string
}

export const LabelValue = ({
  label,
  value,
  textColorEmpty,
  labelColor,
}: Props) => {
  const { t } = useTranslation()

  const preparedValue = value === '' ? null : value
  const isEmpty = preparedValue === null
  const colorEmpty = textColorEmpty ?? 'secondary7.main'

  return (
    <LabelValueWrapper label={label} labelColor={labelColor}>
      <Typography
        component="div"
        variant="h4"
        color={isEmpty ? colorEmpty : 'inherit'}
      >
        {preparedValue ?? t('not_provided')}
      </Typography>
    </LabelValueWrapper>
  )
}
