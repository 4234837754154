import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Typography,
} from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'
import useTranslation from 'next-translate/useTranslation'
import { Translate } from 'next-translate'
import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingButton } from '@mui/lab'
import { Stack } from '@mui/system'
import { TextField } from '../text-field'
import { emailSchema, textFieldError } from 'src/lib/validations'
import { Checkbox } from '../checkbox'

export const createEnterEmailSchema = (t: Translate) =>
  z.object({
    email: emailSchema(t),
    saveEmail: z.boolean(),
  })

export type EnterEmailFieldValues = z.infer<
  ReturnType<typeof createEnterEmailSchema>
>

export type DialogEnterEmailProps = {
  onSave: (value: EnterEmailFieldValues) => void
  onClose: () => void
  isLoading: boolean
  title: string
  submitButtonText?: string
}

type Props = DialogEnterEmailProps & {
  defaultEmail: string
  isSaveEmailDefault?: boolean
}

export const DialogEnterEmail = ({
  defaultEmail,
  onSave,
  onClose,
  isLoading,
  title,
  isSaveEmailDefault,
  submitButtonText,
}: Props) => {
  const { t } = useTranslation()

  const schema = createEnterEmailSchema(t)

  const { control, handleSubmit, watch } = useForm<EnterEmailFieldValues>({
    defaultValues: {
      email: defaultEmail,
      saveEmail: isSaveEmailDefault ?? false,
    },
    resolver: zodResolver(schema),
  })

  return (
    <Dialog open onClose={onClose}>
      <form noValidate onSubmit={handleSubmit(onSave)}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Stack spacing={{ xs: 3, xl: 4 }}>
            <Controller
              control={control}
              name="email"
              render={({ field, fieldState }) => (
                <TextField
                  required
                  {...field}
                  {...textFieldError(fieldState.error)}
                  label={t('email')}
                  placeholder={t('email')}
                />
              )}
            />
            <Stack spacing={{ xs: 1.5, lg: 2 }}>
              <Controller
                control={control}
                name="saveEmail"
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox />}
                    label={t('save_email_as_contact_email')}
                    checked={field.value}
                    onChange={(_event, isChecked) => field.onChange(isChecked)}
                  />
                )}
              />
              <Typography variant="tinyTextRegular" color="secondary8.main">
                {t('save_email_as_contact_email_description')}
              </Typography>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="cancel" size="small">
            {t('cancel')}
          </Button>
          <LoadingButton
            type="submit"
            variant="primary"
            size="small"
            loading={isLoading}
            disabled={watch('email') === ''}
          >
            {submitButtonText ?? t('save')}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}
