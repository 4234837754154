import {
  Grid,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import useTranslation from 'next-translate/useTranslation'
import { isSome } from 'fp-ts/lib/Option'
import { Box } from '@mui/system'
import { CopyIcon } from './icons/copy'
import copy from 'copy-to-clipboard'
import { EventPlace, IEventDetails } from 'src/api'
import { useLocale } from 'src/lib/use-locale'
import { LabelValue, LabelValueWrapper } from 'src/components/label-value'
import { formatEventDate } from '../utils/event-format-date'
import { NextLink } from 'src/components/next-link'
import { pages } from 'src/app/pages'
import { formatEventVenue } from '../utils/event-format-display'
import { toastSuccess } from 'src/components/toaster'
import { Tags } from 'src/components/tags'

const labelColor = 'secondary8.main'

type Props = {
  event: IEventDetails
}

export const EventInformation = ({ event }: Props) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { locale } = useLocale()
  const isSmallDesktop = useMediaQuery(theme.breakpoints.up('lg'))

  const eventPlace = event.eventPlace

  return (
    <Box>
      <Grid
        container
        rowSpacing={{ xs: 1.5, lg: 3 }}
        columnSpacing={{ sm: 1.5, lg: 3 }}
      >
        <Grid item xs={12} sm={7} order={{ sm: 1 }} lg={4.76} xl={4.53}>
          <LabelValue
            label={t('date')}
            labelColor={labelColor}
            value={formatEventDate({
              eventSchedules: event.eventSchedules,
              timeframe: event.timeframe,
              locale,
            })}
          />
        </Grid>
        <Grid item xs={12} order={{ sm: 3, lg: 2 }} sm={7} lg={4.76} xl={4.53}>
          <LabelValueWrapper label={t('venue')} labelColor={labelColor}>
            {eventPlace.placeType === EventPlace.TO_BE_ANNOUNCED ? (
              <Typography variant="h4" component="div">
                {t('somewhere_in', {
                  city: eventPlace.announcedCity,
                })}
              </Typography>
            ) : (
              <NextLink
                href={pages['/venues/[venueSlug]/events']({
                  venueSlug: eventPlace.venue.venueSlug,
                })}
                variant="h4"
                disabled={!eventPlace.venue.active}
                component={!eventPlace.venue.active ? 'div' : undefined}
                sx={{
                  '&[disabled]': {
                    color: 'common.black',
                  },
                }}
              >
                {formatEventVenue(eventPlace.venue)}
              </NextLink>
            )}
          </LabelValueWrapper>
        </Grid>
        <Grid item xs={12} sm={7} order={{ sm: 5, lg: 4 }} lg={4.76} xl={4.53}>
          <LabelValueWrapper label={t('organizer')} labelColor={labelColor}>
            <NextLink
              variant="h4"
              href={pages['/organizations/[organizationSlug]']({
                organizationSlug: event.organization.organizationSlug,
              })}
            >
              {event.organization.name}
            </NextLink>
          </LabelValueWrapper>
        </Grid>
        <Grid item xs={12} order={{ sm: 2, lg: 3 }} sm={5} lg={2.48} xl={2.94}>
          <LabelValue
            label={t('age_limit')}
            value={
              isSome(event.ageLimit) && event.ageLimit.value
                ? `${event.ageLimit.value}+`
                : t('no_limit')
            }
            labelColor={labelColor}
          />
        </Grid>
        <Grid item xs={12} sm={5} order={{ sm: 4, lg: 6 }} lg={2.48} xl={2.94}>
          <LabelValueWrapper label={t('event_id')} labelColor={labelColor}>
            <Stack
              direction="row"
              spacing={{ xs: 0.25 }}
              alignItems={{ xs: 'flex-start', lg: 'center' }}
            >
              <Typography variant="h4">{event.eventHumanId}</Typography>
              <IconButton
                onClick={() => {
                  const isCopied = copy(event.eventHumanId)
                  if (isCopied) {
                    toastSuccess(t('alert_copied_to_clipboard'), {
                      position: isSmallDesktop ? 'bottom-right' : 'top-center',
                    })
                  }
                }}
                sx={{
                  p: 0,
                  height: { xs: 20, lg: 26 },
                  width: { xs: 20, lg: 26 },
                  pt: { lg: '4.64px' },
                  pb: { lg: '4.97px' },
                }}
              >
                <CopyIcon sx={{ fontSize: { xs: 15.13, lg: 19.6 } }} />
              </IconButton>
            </Stack>
          </LabelValueWrapper>
        </Grid>
        <Grid item xs={12} sm={5} order={{ sm: 6, lg: 5 }} lg={4.76} xl={4.53}>
          <LabelValueWrapper
            labelColor={labelColor}
            label={t('genres')}
            spacing={{ xs: 0.5 }}
          >
            <Tags tags={event.eventTags} />
          </LabelValueWrapper>
        </Grid>
      </Grid>
    </Box>
  )
}
