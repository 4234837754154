import { EventJsonLd, NextSeo } from 'next-seo'
import { formatInTimeZone } from 'date-fns-tz'
import { toNullable } from 'fp-ts/lib/Option'
import {
  EventPlace,
  EventProgrammeStatus,
  IEventArtist,
  IEventArtists,
  IEventArtistsByAct,
  IEventDetails,
} from 'src/api'
import { useLocale } from 'src/lib/use-locale'
import { formatEventCityCountry } from '../utils/event-format-display'
import { getSeoDescription } from 'src/components/rich-text-output'
import { getAbsoluteUrlWithLocale } from 'src/lib/get-path-with-locale'
import { pages } from 'src/app/pages'
import { formatEventDateWithoutTime } from '../utils/event-format-date'

const flatArtistsByAct = (artists: IEventArtistsByAct) => {
  return [
    ...(toNullable(artists.LARGE) ?? []),
    ...(toNullable(artists.MEDIUM) ?? []),
    ...(toNullable(artists.SMALL) ?? []),
    ...(toNullable(artists.MAIN) ?? []),
    ...(toNullable(artists.SUPPORT) ?? []),
  ]
}

const geArtistsNames = (artists: Array<IEventArtist>) => {
  return [...new Set(artists.map((artist) => artist.performer.name))]
}

type Props = {
  event: IEventDetails
  programme: IEventArtists
}

export const EventSeo = ({ event, programme }: Props) => {
  const { locale } = useLocale()

  const eventSchedule = event.eventSchedules[0]
  const eventPlace = event.eventPlace

  const artistsByAct =
    programme.status === EventProgrammeStatus.LIST &&
    'performersByAct' in programme &&
    geArtistsNames(flatArtistsByAct(programme.performersByAct))

  const artists = artistsByAct

  return (
    <>
      <NextSeo
        noindex={event.hidden}
        title={`${event.name} // ${formatEventDateWithoutTime({
          eventSchedules: event.eventSchedules,
          timeframe: event.timeframe,
          locale,
        })} // ${formatEventCityCountry(event)}`}
        description={getSeoDescription(event.description)}
        openGraph={{
          type: 'website',
          url: getAbsoluteUrlWithLocale({
            locale,
            path: pages['/events/[eventSlug]']({
              eventSlug: event.eventSlug,
            }),
          }),
          images: [{ url: event.mainImage.url }],
        }}
      />
      <EventJsonLd
        description={getSeoDescription(event.description)}
        name={event.name}
        startDate={formatInTimeZone(
          eventSchedule.startDate,
          eventSchedule.timezone,
          "yyyy-MM-dd'T'HH:mm:ssXXX",
        )}
        endDate={eventSchedule.endDate}
        location={
          eventPlace.placeType === EventPlace.VENUE ||
          eventPlace.placeType === EventPlace.VENUE_WITH_MAP
            ? {
                name: eventPlace.venue.name,
                address: {
                  addressCountry: eventPlace.venue.country,
                  addressLocality: eventPlace.venue.city,
                  postalCode: eventPlace.venue.zipCode,
                  streetAddress: `${eventPlace.venue.street} ${eventPlace.venue.streetNumber}`,
                },
              }
            : {
                name: eventPlace.announcedCity,
              }
        }
        eventAttendanceMode="OfflineEventAttendanceMode"
        eventStatus="EventScheduled"
        images={[event.mainImage.url]}
        organizer={{
          name: event.organization.name,
          url: event.organization.website,
          type: 'Organization',
        }}
        performers={
          artists
            ? artists.map((artist) => ({
                name: artist,
              }))
            : undefined
        }
      />
    </>
  )
}
