import { Stack, useMediaQuery, useTheme } from '@mui/material'
import { Box } from '@mui/system'
import { useQuery, useQueryClient } from 'react-query'
import useTranslation from 'next-translate/useTranslation'
import { ArtistsOneDay } from './artists/artists-one-day'
import { ArtistsMultipleDay } from './artists/artists-multiple-day'
import { SalesInfo } from './sales-info'
import { isNonEmpty } from 'fp-ts/lib/ReadonlyArray'
import { EventHeader } from './event-header'
import { EventInformation } from './event-information'
import { EventSeo } from './event-seo'
import { HeaderDecor } from './header-decor'
import {
  EventProgrammeStatus,
  EventTimeframe,
  getEvent,
  getEventArtists,
  getMedia,
  IEventArtists,
  IEventDetails,
  IMedia,
  IMediaImage,
  MediaReferenceType,
  qk,
} from 'src/api'
import { useIsSessionLoaded } from 'src/lib/use-is-session-loaded'
import { useLocale } from 'src/lib/use-locale'
import { useFavoriteEvent } from '../use-favorite'
import { DialogSignIn } from 'src/modules/sign-in/dialog-sign-in'
import { HeaderGradient } from 'src/layouts/header-gradient'
import { exploreHeaderGradient } from 'src/assets/gradients'
import { spacingBetweenSections } from 'src/layouts/spacing'
import { DetailsSection } from 'src/components/details-section'
import { RichTextOutput } from 'src/components/rich-text-output'
import { PhotoGallery } from 'src/components/photo-gallery'
import { useEffect } from 'react'
import { sendEventDetailsPageViewAnalytics } from 'src/app/analytics'

export type EventDetailsPageProps = {
  event: IEventDetails
  media: Array<IMedia>
  artists: IEventArtists
}

export const EventDetailsPage = ({
  event,
  media,
  artists,
}: EventDetailsPageProps) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const isSessionLoaded = useIsSessionLoaded()
  const { locale } = useLocale()

  const theme = useTheme()
  const isSmallDesktop = useMediaQuery(theme.breakpoints.up('lg'))

  const eventSlug = event.eventSlug
  const $event = useQuery(
    qk.cms.public.event.details.toKeyWithArgs({ eventSlug, locale }),
    () => getEvent({ eventSlug, locale }),
    {
      initialData: event,
      enabled: isSessionLoaded,
    },
  )

  const { isFavorite, onAddFavorite, dialogUnauthenticated } = useFavoriteEvent(
    {
      eventId: event.eventId,
      eventName: event.name,
      isFavoriteDefault: $event.data?.favourite ?? false,
      onSuccessAdd: () => {
        queryClient.invalidateQueries(
          qk.cms.public.event.details.toKeyWithArgs({
            eventSlug,
            locale,
          }),
        )
      },
    },
  )

  const $media = useQuery(
    qk.cms.public.media.toKeyWithArgs({
      slug: eventSlug,
      referenceType: MediaReferenceType.EVENT,
    }),
    () =>
      getMedia({
        slug: eventSlug,
        referenceType: MediaReferenceType.EVENT,
      }),
    { initialData: media },
  )

  const $artists = useQuery(
    qk.cms.public.event.artists.toKeyWithArgs({
      eventSlug,
    }),
    () => getEventArtists({ eventSlug }),
    { initialData: artists },
  )

  useEffect(() => {
    sendEventDetailsPageViewAnalytics(event)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (
    $event.data === undefined ||
    $media.data === undefined ||
    $artists.data === undefined
  ) {
    return null
  }

  const eventData = $event.data
  const mediaData = $media.data
  const artistsData = $artists.data

  const photos = mediaData.filter(
    (media) => media.mediaType === 'FILE',
  ) as Array<IMediaImage>

  return (
    <>
      {dialogUnauthenticated.isTrue && (
        <DialogSignIn onClose={dialogUnauthenticated.setFalse} />
      )}
      <EventSeo event={eventData} programme={artistsData} />
      <Box>
        <Box
          minHeight={{ xs: '100vh', lg: 'auto' }}
          pb={{
            xs: 4,
            sm: 6,
            lg: 9,
            xl: 10,
          }}
        >
          <HeaderGradient
            decors={<HeaderDecor />}
            gradient={exploreHeaderGradient}
            paddingTopXl={4}
            gradientHeight={{
              xs: '258px',
              sm: '264px',
              lg: '396px',
              xl: '445px',
            }}
          >
            <Stack spacing={spacingBetweenSections}>
              <EventHeader
                event={eventData}
                isFavorite={isFavorite}
                onClickAddFavorite={onAddFavorite}
              />
              <EventInformation event={eventData} />
              <DetailsSection title={t('about_the_event')}>
                <RichTextOutput content={eventData.description} />
              </DetailsSection>
              {artistsData.status === EventProgrammeStatus.LIST &&
                'performersByAct' in artistsData &&
                (eventData.timeframe === EventTimeframe.ONE_DAY ? (
                  <DetailsSection title={t('main_act')}>
                    <ArtistsOneDay artists={artistsData.performersByAct} />
                  </DetailsSection>
                ) : (
                  <DetailsSection title={t('artists')}>
                    <ArtistsMultipleDay artists={artistsData.performersByAct} />
                  </DetailsSection>
                ))}
              {isNonEmpty(photos) && (
                <DetailsSection title={t('photo_gallery')}>
                  <PhotoGallery photos={photos} />
                </DetailsSection>
              )}
            </Stack>
          </HeaderGradient>
        </Box>
        {!isSmallDesktop && (
          <SalesInfo
            event={eventData}
            isFavorite={isFavorite}
            onAddToFavorite={onAddFavorite}
          />
        )}
      </Box>
    </>
  )
}
