import { Box, Stack, Typography } from '@mui/material'
import { IEventDetails, EventStatus } from 'src/api'
import { ShareButton } from 'src/components/share-button'
import {
  headerImageBorderRadius,
  headerImageHeight,
} from 'src/layouts/header-gradient'
import { isNonEmptyString } from 'src/lib/types'
import { useIsSessionLoaded } from 'src/lib/use-is-session-loaded'
import { useLocale } from 'src/lib/use-locale'
import {
  actionButtonBackground,
  AddToFavorites,
} from 'src/modules/favorites/add-favorite'
import { formatEventCityCountry } from '../utils/event-format-display'
import { SalesInfo } from './sales-info'
import {
  ResponsiveImage,
  getHeaderImageSrcSet,
  getLogoSrcSet,
} from 'src/components/responsive-image'
import { isSome } from 'fp-ts/lib/Option'
import { formatEventDateWithoutTime } from '../utils/event-format-date'

const logoProportion = { xs: 73, sm: 80, lg: 96, xl: 108 }

type Props = {
  event: IEventDetails
  isFavorite: boolean
  onClickAddFavorite: (isChecked: boolean) => void
}

export const EventHeader = ({
  event,
  isFavorite,
  onClickAddFavorite,
}: Props) => {
  const isSessionLoaded = useIsSessionLoaded()
  const { locale } = useLocale()

  const nameSubheadingSection = (
    <>
      <Typography variant="smallTextSemiBold">
        {event.eventType.name}
      </Typography>
      <Typography variant="h1">{event.name}</Typography>
      {isNonEmptyString(event.subheading) && (
        <Typography variant="h3Bold" component="h2">
          {event.subheading}
        </Typography>
      )}
    </>
  )

  return (
    <Stack spacing={{ xs: 2.25, sm: 0 }}>
      <Stack
        spacing={{ xs: 0.5 }}
        sx={{ color: 'common.white' }}
        display={{ sm: 'none' }}
      >
        {nameSubheadingSection}
      </Stack>
      <Stack>
        <Box
          height={{ ...headerImageHeight, xl: 515 }}
          width="100%"
          position="relative"
          sx={{
            '& .eventMainImage': {
              borderRadius: headerImageBorderRadius,
            },
          }}
        >
          <Box
            position="absolute"
            width="100%"
            height="100%"
            sx={{
              background:
                'linear-gradient(0.19deg, rgba(0, 0, 0, 0.58) 0.16%, rgba(0, 0, 0, 0) 73.91%)',
              zIndex: 2,
              borderRadius: headerImageBorderRadius,
            }}
          />
          <ResponsiveImage
            alt={event.name}
            originalSizeUrl={event.mainImage.url}
            hasPriority
            className="eventMainImage"
            srcSet={getHeaderImageSrcSet(event.mainImage.resizedUrls)}
          />
          {isSome(event.logoSetting) && (
            <Box
              sx={{
                position: 'absolute',
                zIndex: 3,
                width: logoProportion,
                height: logoProportion,
                left: { xs: 16, sm: 'auto' },
                bottom: { xs: 16, sm: 'auto' },
                top: { sm: 18, lg: 24 },
                right: { sm: 18, lg: 24 },
                '& .eventLogo': {
                  borderRadius: '50%',
                  backgroundColor: event.logoSetting.value.logoBackgroundColor,
                },
              }}
            >
              <ResponsiveImage
                className="eventLogo"
                srcSet={getLogoSrcSet(event.logoSetting.value.logo.resizedUrls)}
                alt={event.name}
                originalSizeUrl={event.logoSetting.value.logo.url}
              />
            </Box>
          )}
          <Stack
            sx={{
              position: 'absolute',
              zIndex: 3,
              left: { sm: 18, lg: 24 },
              bottom: { sm: 18, lg: 24 },
              right: { sm: 72, lg: 419, xl: 384 },
              color: 'common.white',
            }}
            display={{ xs: 'none', sm: 'flex' }}
            spacing={{ sm: 1, lg: isNonEmptyString(event.subheading) ? 2 : 1 }}
          >
            <Stack spacing={{ sm: 0.25, xl: 0.5 }}>
              {nameSubheadingSection}
            </Stack>
            <Typography variant="h4">{`${formatEventDateWithoutTime({
              eventSchedules: event.eventSchedules,
              timeframe: event.timeframe,
              locale,
            })} // ${formatEventCityCountry(event)}`}</Typography>
          </Stack>
          <Stack
            spacing={{ xs: 2 }}
            direction={{ lg: 'row' }}
            sx={{
              position: 'absolute',
              zIndex: 3,
              bottom: { xs: 16, sm: 18, lg: 102, xl: 110 },
              right: { xs: 16, sm: 18, lg: 24 },
            }}
          >
            <Box
              sx={{
                ...actionButtonBackground,
              }}
            >
              <ShareButton
                buttonProportion={{ xs: 38 }}
                iconProportion={{ xs: 24 }}
                title={event.name}
                id={event.eventId}
                entityType="event"
              />
            </Box>
            {event.status === EventStatus.PUBLISHED && (
              <Box
                sx={{
                  ...actionButtonBackground,
                }}
              >
                <AddToFavorites
                  isFavorite={isFavorite}
                  isFavoriteLoading={!isSessionLoaded}
                  onClick={onClickAddFavorite}
                  color="common.white"
                  iconProportion={{ xs: 24 }}
                  buttonProportion={{ xs: 38 }}
                />
              </Box>
            )}
          </Stack>
          <Box display={{ xs: 'none', lg: 'block' }}>
            <SalesInfo
              event={event}
              isFavorite={isFavorite}
              onAddToFavorite={onClickAddFavorite}
            />
          </Box>
        </Box>
      </Stack>
    </Stack>
  )
}
