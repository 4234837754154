
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { InferGetServerSidePropsType } from 'next';
import { getEvent, getEventArtists, getMedia, MediaReferenceType, } from 'src/api';
import { defaultApplicationLocale } from 'src/app/locale';
import { validateUrlStringParam } from 'src/lib/validate-url-param';
import { EventDetailsPage, EventDetailsPageProps, } from 'src/modules/event/details';
const getServerSideProps = validateUrlStringParam<EventDetailsPageProps>({
    paramName: 'eventSlug',
    notFoundErrorCode: 'error_event_not_found',
    onSuccess: async (eventSlug, { locale }) => {
        const args = {
            locale: locale ?? defaultApplicationLocale,
            eventSlug,
        };
        const [event, media, artists] = await Promise.all([
            getEvent(args),
            getMedia({
                slug: eventSlug,
                referenceType: MediaReferenceType.EVENT,
                ...args,
            }),
            getEventArtists({
                eventSlug: eventSlug,
            }),
        ]);
        return {
            props: {
                event,
                media,
                artists,
                locales: event.availableLanguages,
            },
        };
    },
});
const EventDetails = ({ event, media, artists, }: InferGetServerSidePropsType<typeof getServerSideProps>) => {
    return <EventDetailsPage event={event} media={media} artists={artists}/>;
};
EventDetails.paddingBottom = 0;
EventDetails.displayFooterBreakpoints = { xs: 'none', lg: 'block' };
export default EventDetails;

    async function __Next_Translate__getServerSideProps__18d6016cbb7__(ctx) {
      const res = await getServerSideProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/events/[eventSlug]/index',
            loaderName: 'getServerSideProps',
            loadLocaleFrom: __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getServerSideProps__18d6016cbb7__ as getServerSideProps }
  