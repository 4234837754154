import { isSome } from 'fp-ts/lib/Option'
import { Stack } from '@mui/system'
import { IEventArtistsByAct } from 'src/api'
import {
  ArtistActSection,
  eventArtistsToArtistSection,
} from 'src/modules/artist/card'

type Props = {
  artists: IEventArtistsByAct
}

export const ArtistsMultipleDay = ({ artists }: Props) => {
  const largeAct = artists.LARGE
  const mediumAct = artists.MEDIUM
  const smallAct = artists.SMALL

  return (
    <Stack spacing={{ xs: 2.25, lg: 3 }}>
      {isSome(largeAct) && (
        <ArtistActSection
          artists={eventArtistsToArtistSection(largeAct.value)}
          cardSize="large"
          columns={2}
        />
      )}
      {isSome(mediumAct) && (
        <ArtistActSection
          artists={eventArtistsToArtistSection(mediumAct.value)}
          cardSize="medium"
          columns={3}
        />
      )}
      {isSome(smallAct) && (
        <ArtistActSection
          artists={eventArtistsToArtistSection(smallAct.value)}
          cardSize="small"
          columns={4}
        />
      )}
    </Stack>
  )
}
