import { IconButton, useTheme, useMediaQuery } from '@mui/material'
import copy from 'copy-to-clipboard'
import useTranslation from 'next-translate/useTranslation'
import { ResponsiveStyleValue } from '@mui/system/styleFunctionSx'
import { sendShareAnalytics } from 'src/app/analytics'
import { toastSuccess } from '../toaster'
import { ShareIcon } from 'src/assets/icons'

type Props = {
  title: string
  id: string
  entityType: string
  iconProportion: ResponsiveStyleValue<number>
  buttonProportion: ResponsiveStyleValue<number>
}

export const ShareButton = ({
  title,
  id,
  entityType,
  iconProportion,
  buttonProportion,
}: Props) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isSmallDesktop = useMediaQuery(theme.breakpoints.up('lg'))

  const handleShare = () => {
    sendShareAnalytics({ id, entityType, name: title })
    if (navigator.share) {
      navigator.share({
        title: title,
        url: window.location.href,
      })
    } else {
      const isCopied = copy(window.location.href)
      if (isCopied) {
        toastSuccess(t('alert_copied_to_clipboard'), {
          position: isSmallDesktop ? 'bottom-right' : 'top-center',
        })
      }
    }
  }

  return (
    <IconButton
      onClick={() => handleShare()}
      sx={{ p: { xs: 0 }, height: buttonProportion, width: buttonProportion }}
    >
      <ShareIcon sx={{ fontSize: iconProportion }} />
    </IconButton>
  )
}
