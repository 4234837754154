import { ReactNode } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import useTranslation from 'next-translate/useTranslation'
import { LoadingButton } from '@mui/lab'
import { useRouter } from 'next/router'
import {
  getMinimumTicketPrice,
  checkPossibleTicketsNotification,
  IEventDetails,
  notifyOnSalesStart,
  qk,
  EventStatus,
  MinPriceStatus,
  EventPlace,
} from 'src/api'
import { useIsSessionLoaded } from 'src/lib/use-is-session-loaded'
import { pages } from 'src/app/pages'
import {
  DialogSignIn,
  useAuthenticationRequired,
} from 'src/modules/sign-in/dialog-sign-in'
import { useBoolean } from 'src/lib/use-boolean'
import { globalAccessToken } from 'src/lib/use-auth'
import { DialogEnterEmailWithDefaultContact } from 'src/components/dialog-enter-email'
import { BellIcon, CancelledIcon, CompletedIcon } from 'src/assets/icons'
import {
  actionButtonBackground,
  AddToFavorites,
} from 'src/modules/favorites/add-favorite'

type BuyTicketsProps = {
  minTicketPrice: number
  isFeesIncluded: boolean
  event: IEventDetails
}

const BuyTickets = ({
  minTicketPrice,
  event,
  isFeesIncluded,
}: BuyTicketsProps) => {
  const { t } = useTranslation()
  const isSessionLoaded = useIsSessionLoaded()
  const router = useRouter()
  const isEventFree = minTicketPrice === 0
  const buttonText = isEventFree ? t('get_tickets') : t('buy_tickets')

  const checkoutPath =
    event.eventPlace.placeType === EventPlace.VENUE_WITH_MAP
      ? pages['/events/[eventSlug]/checkout/seat-map']({
          eventSlug: event.eventSlug,
        })
      : pages['/events/[eventSlug]/checkout']({
          eventSlug: event.eventSlug,
        })

  const { dialogUnauthenticated, onClickAuthenticationRequired } =
    useAuthenticationRequired({
      authorizedAction: () => router.push(checkoutPath),
    })

  return (
    <>
      {dialogUnauthenticated.isTrue && (
        <DialogSignIn onClose={dialogUnauthenticated.setFalse} />
      )}
      <Stack
        direction="row"
        justifyContent="space-between"
        py={{ xs: 1.75, sm: 2.25, lg: 0 }}
        px={{ xs: 2, sm: 3.5, lg: 0 }}
        alignItems="center"
        spacing={{ lg: 5 }}
      >
        {!isEventFree && (
          <Box>
            <Stack spacing={{ sm: '1px', lg: 0 }}>
              <Typography
                component="div"
                variant="smallTextSemiBold"
                color={{ xs: 'secondary9.main', lg: 'secondary6.main' }}
              >
                {t('from')}
              </Typography>
              <Typography
                variant="h2Black"
                component="div"
                color={{ lg: 'common.white' }}
              >{`${event.currency} ${minTicketPrice}`}</Typography>
            </Stack>
            <Typography
              variant="tinyTextRegular"
              component="div"
              color={{ xs: 'secondary8.main', lg: 'common.white' }}
            >
              {isFeesIncluded
                ? t('service_fees_included')
                : t('service_fees_excluded')}
            </Typography>
          </Box>
        )}

        <LoadingButton
          loading={!isSessionLoaded}
          variant="primary"
          fullWidth={isEventFree}
          size="big"
          sx={{ alignSelf: { lg: 'flex-end' } }}
          onClick={onClickAuthenticationRequired}
        >
          {buttonText}
        </LoadingButton>
      </Stack>
    </>
  )
}

type NotifyOnSalesStartProps = {
  isFavorite: boolean
  onAddToFavorite: (isChecked: boolean) => void
}

const NotifyOnSalesStart = ({
  isFavorite,
  onAddToFavorite,
  eventSlug,
}: NotifyOnSalesStartProps & { eventSlug: string }) => {
  const { t } = useTranslation()
  const isSessionLoaded = useIsSessionLoaded()
  const queryClient = useQueryClient()

  const dialogEmailForNotification = useBoolean(false)
  const $notifyOnSalesStart = useMutation(notifyOnSalesStart)

  const { dialogUnauthenticated, onClickAuthenticationRequired } =
    useAuthenticationRequired({
      authorizedAction: dialogEmailForNotification.setTrue,
    })

  const $notifyMe = useQuery(
    qk.trade.ticket.notifyMe.toKeyWithArgs({
      eventSlug,
    }),
    () => checkPossibleTicketsNotification({ eventSlug }),
    {
      enabled: globalAccessToken !== null,
    },
  )

  const noticeAvailable = $notifyMe.data?.noticeAvailable

  return (
    <>
      <DialogEnterEmailWithDefaultContact
        isOpen={dialogEmailForNotification.isTrue}
        onClose={dialogEmailForNotification.setFalse}
        onSave={({ email, saveEmail }) =>
          $notifyOnSalesStart.mutate(
            { email, eventSlug, contactEmail: saveEmail },
            {
              onSuccess: () => {
                dialogEmailForNotification.setFalse()
                queryClient.invalidateQueries(
                  qk.trade.ticket.notifyMe.toKeyWithArgs({
                    eventSlug,
                  }),
                )
              },
            },
          )
        }
        isLoading={$notifyOnSalesStart.isLoading}
        title={t('enter_your_email_here')}
        submitButtonText={t('notify')}
      />
      {dialogUnauthenticated.isTrue && (
        <DialogSignIn onClose={dialogUnauthenticated.setFalse} />
      )}
      <Stack
        py={{ xs: 2.5, lg: 0 }}
        px={{ xs: 2, sm: 3.5, lg: 0 }}
        direction="row"
        spacing={2.375}
        alignItems="center"
      >
        <LoadingButton
          startIcon={<BellIcon sx={{ fontSize: { xs: 18, sm: 20, lg: 22 } }} />}
          variant="primary"
          size="big"
          disabled={globalAccessToken !== null && !noticeAvailable}
          sx={{
            '& .MuiButton-startIcon': {
              marginRight: { xs: 0.5, lg: 0.875 },
              marginLeft: 0,
            },
            width: { xs: '100%', lg: 'auto' },
          }}
          onClick={onClickAuthenticationRequired}
          loading={!isSessionLoaded}
        >
          {t('notify_on_sales_start')}
        </LoadingButton>
        <Box display={{ lg: 'none' }} sx={actionButtonBackground}>
          <AddToFavorites
            isFavoriteLoading={!isSessionLoaded}
            isFavorite={isFavorite}
            onClick={onAddToFavorite}
            iconProportion={{ xs: 25 }}
            buttonProportion={{ xs: 40 }}
          />
        </Box>
      </Stack>
    </>
  )
}

const EventStatusSection = ({
  label,
  icon,
}: {
  label: string
  icon?: ReactNode
}) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      spacing={1}
      sx={{
        background: 'rgba(255, 255, 255, 0.35)',
        borderRadius: '8px',
        py: { xs: 2.5, lg: 1.5 },
        px: 3,
      }}
    >
      {icon !== undefined && icon}
      <Typography
        variant="h3ExtraBold"
        color={{ xs: 'common.black', lg: 'common.white' }}
      >
        {label}
      </Typography>
    </Stack>
  )
}

type Props = {
  event: IEventDetails
} & NotifyOnSalesStartProps

export const SalesInfo = ({ event, isFavorite, onAddToFavorite }: Props) => {
  const { t } = useTranslation()
  const eventSlug = event.eventSlug
  const $salesInfo = useQuery(
    qk.trade.public.event.ticketMinPrice.toKeyWithArgs({
      eventSlug,
    }),
    () => getMinimumTicketPrice({ eventSlug }),
    { enabled: event.status === EventStatus.PUBLISHED },
  )

  const eventStatusAssets = {
    CANCELLED: {
      icon: (
        <CancelledIcon
          sx={{
            fontSize: 24,
            color: { xs: 'primary2.main', lg: 'common.white' },
          }}
        />
      ),
      label: t('event_cancelled'),
    },
    COMPLETED: {
      icon: (
        <CompletedIcon
          sx={{
            fontSize: 24,
            color: { xs: 'secondary2.main', lg: 'common.white' },
          }}
        />
      ),
      label: t('event_completed'),
    },
  } as const

  return (
    <Stack
      sx={{
        position: { xs: 'sticky', lg: 'absolute' },
        bottom: { xs: 0, lg: 24, xl: 20 },
        left: { xs: 0, lg: 'initial' },
        right: { lg: 24 },
        backgroundColor: { xs: 'common.white', lg: 'transparent' },
        boxShadow: { xs: '0px 0px 15px rgba(0, 0, 0, 0.1)', lg: 'none' },
        zIndex: 4,
      }}
    >
      {$salesInfo.isSuccess && (
        <>
          {$salesInfo.data.status === MinPriceStatus.ON_SALE && (
            <BuyTickets
              minTicketPrice={$salesInfo.data.minTicketPrice}
              isFeesIncluded={$salesInfo.data.feesIncluded}
              event={event}
            />
          )}
          {$salesInfo.data.status === MinPriceStatus.NOTIFY && (
            <NotifyOnSalesStart
              eventSlug={event.eventSlug}
              isFavorite={isFavorite}
              onAddToFavorite={onAddToFavorite}
            />
          )}
          {$salesInfo.data.status === MinPriceStatus.SOLD_OUT && (
            <EventStatusSection label={t('tickets_sold_out')} />
          )}
          {$salesInfo.data.status === MinPriceStatus.FREE_WITHOUT_TICKETS && (
            <EventStatusSection label={t('no_tickets_required')} />
          )}
        </>
      )}

      {event.status !== EventStatus.PUBLISHED && (
        <EventStatusSection
          {...(event.status === EventStatus.CANCELLED
            ? eventStatusAssets.CANCELLED
            : eventStatusAssets.COMPLETED)}
        />
      )}
    </Stack>
  )
}
