import { Box } from '@mui/material'
import { CurveLine, Dot } from 'src/assets/icons'

export const HeaderDecor = () => {
  return (
    <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
      <Box
        sx={{
          position: 'absolute',
          top: { lg: '57px' },
          left: { lg: '42px', xl: '66px' },
        }}
      >
        <Dot sx={{ color: 'secondary1.main', fontSize: { lg: 8, xl: 10 } }} />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: { lg: '224px', xl: '215px' },
          left: { lg: '85px', xl: '146px' },
        }}
      >
        <Dot sx={{ color: 'accent1.main', fontSize: { lg: 6, xl: 9 } }} />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: { lg: '233px', xl: '193px' },
          right: { lg: '91px', xl: '75px' },
        }}
      >
        <Dot sx={{ color: 'accent1.main', fontSize: { lg: 10, xl: 14 } }} />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: { lg: '353px', xl: '394px' },
          right: { lg: '43px' },
        }}
      >
        <Dot sx={{ color: 'accent2.main', fontSize: { lg: 8, xl: 10 } }} />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: { lg: '334px', xl: '357px' },
          left: { lg: '26px', xl: '30px' },
        }}
      >
        <CurveLine
          sx={{
            color: 'secondary2.main',
            fontSize: { lg: 27, xl: 50 },
            transform: 'rotate(60deg)',
          }}
        />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: { lg: '2px' },
          right: { lg: '-8px', xl: '-10px' },
        }}
      >
        <CurveLine sx={{ color: 'accent2.main', fontSize: { lg: 48 } }} />
      </Box>
    </Box>
  )
}
