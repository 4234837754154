import { CheckboxProps } from '@mui/material'
import { Checkbox as MuiCheckbox } from '@mui/material'

export const Checkbox = ({ sx, ...props }: CheckboxProps) => {
  return (
    <MuiCheckbox
      {...props}
      sx={{
        width: '24px',
        height: '24px',
        border: '1px solid',
        borderColor: 'middleGrey.main',
        borderRadius: '4px',
        backgroundColor: 'common.white',
        padding: 0,
        '&:hover': {
          backgroundColor: 'common.white',
        },
        ...sx,
        flexShrink: 0,
      }}
      disableTouchRipple
    />
  )
}
